/*************************************************************
**************************************************************
	ClosingPro stylesheet
**************************************************************
**************************************************************/

/*************************************************
	Specialized sizing for particular grid pages...
	(same as smallish...)
 *************************************************/

wackadoo-active-contracts [wackadoogridcontentplaceholder]
, wackadoo-attorneys [wackadoogridcontentplaceholder]
, wackadoo-lenders [wackadoogridcontentplaceholder]
{
	font-size: 0.85em !important;
}

/********************
  Task detail
 *******************/

.high
{
	color : red;
}

.medium
{
	color : purple;
}

.normal
{
	color : $wackadoo-other-green;
}

.low
{
	color : gray;
}

/* Stuff for the TaskList/TaskTemplateList panel items */

.progress-bar {
	font-size: 0.75em;
}

span.badge
{
	color: white;
	background-color : gray;
    border-radius: 0.67em;
	vertical-align: middle;
}

span.badge.selected
{
	background-color : $wackadoo-other-green;
}

wackadoo-related-entity-detail .col-sm-1,
wackadoo-related-entity-detail .col-sm-2,
wackadoo-related-entity-detail .col-sm-3,
wackadoo-related-entity-detail .col-sm-4,
wackadoo-related-entity-detail .col-sm-5,
wackadoo-related-entity-detail .col-sm-6,
wackadoo-related-entity-detail .col-sm-7,
wackadoo-related-entity-detail .col-sm-8,
wackadoo-related-entity-detail .col-sm-9,
wackadoo-related-entity-detail .col-sm-10,
wackadoo-related-entity-detail .col-sm-11,
wackadoo-related-entity-detail .col-sm-12
{
	padding-left: 3px !important;
	padding-right: 3px !important;
}

.min-width-64-em
{
	min-width: 64em !important;
}
