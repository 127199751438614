/*************************************************************
	GLOBAL FIX FOR WORKING WITH FLOATS...
**************************************************************/
/* 
Add the "group" class to a container element to contain it's floats, 
without needing to specify a fixed height. See: 

	http://nicolasgallagher.com/micro-clearfix-hack.

If you need a quick refresher on working with floats, see: 
	http://css-tricks.com/all-about-floats. 
	
*/
.floatGroup:before,
.floatGroup:after {
   content:"";
   display:table;
}
.floatGroup:after {
   clear:both;
}
.floatGroup {
   zoom:1; /* For IE 6/7 (trigger hasLayout) */
}

.floatRight
{
	float: right;
}

.floatLeft
{
	float: left;
}

.floatCenter
{
	float: center;
}

/*************************************************************
	wackadoo branding stuff
**************************************************************/

.wackadoo-line
{
	border:none;
	margin: 0;
	height: 2px;
	width: 100%;
	
	background-image:
		linear-gradient(
			to right,
			rgba(0, 0, 0, 0),
			$wackadoo-green 5%,
			$wackadoo-green 90%,
			rgba(0, 0, 0, 0)
		);
}

.wackadoo-line-bottom
{
	border-bottom: 2px solid transparent;
	border-image-source: 
	  linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      $wackadoo-green 5%,
      $wackadoo-green 90%,
      rgba(0, 0, 0, 0)
		);
	border-image-outset: 0;
  border-image-slice: 0 0 2 0;
	border-image-repeat: stretch;
	border-image-width: 1;
}

.wackadoo-line-top
{
  border-top: 2px solid transparent;
	border-image-source: 
	  linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      $wackadoo-green 5%,
      $wackadoo-green 90%,
      rgba(0, 0, 0, 0)
		);
	border-image-outset: 0;
  border-image-slice: 2 0 0 0;
	border-image-repeat: stretch;
	border-image-width: 1;
}

/*************************************************************
	Stripe branding stuff
**************************************************************/

.stripeBlue {
	color: $stripe-blue;
}

/*************************************************************
	Leaflet - keep relative z-index in mapping display 
	but slide it UNDER the page menu on phones...
**************************************************************/

.leaflet-pane {
	z-index: 47 !important;
}

.leaflet-control {
	z-index: 48 !important;
}

.leaflet-top,
.leaflet-bottom {
	z-index: 49 !important;
}

/**************************************************************
 These are the general styles for the overall web applications
***************************************************************/

body.waiting * 
{
	cursor: wait; 
}

html, body 
{
	font-size: 1em;
	margin: 0;
	padding: 0;
	height: 100vh;
	width: 100vw;
	max-height: 100vh;
	min-height: 100vh;
	max-width: 100vw;
	min-width: 100vw;
	overflow: hidden;
}

td
{
	padding-left : 0.67em ;
	padding-right : 0.67em;
}

a {
    cursor: pointer;
}

a:hover 
{
	text-decoration: none !important; // no underline...
	cursor: pointer;
	font-weight: 600;
}

select[size],
select[multiple]
 {
	width: auto;
}

select:focus, 
textarea:focus, 
button:focus,
.btn:focus,
.btn.btn-secondary:focus,
.btn.btn-outline-secondary:focus,
.actionButton:focus,
input:focus,
input[type=checkbox]:focus
{
	outline: $wackadoo-green solid thin !important;
}

a:focus,
input:active
{
	outline: none !important;
}

ul, ol
{
	margin-top: 0px !important;
	margin-bottom: 0px !important;
}

.navbar-toggler
{
	outline: none !important;
}

/*
 * action-button stuff
 * adds in text on the buttons and menus...
 *
 */

.actionButton,
button.btn.btn-secondary,
button.btn.btn-secondary.actionButton
{
	color: black;
	background-color: whitesmoke;
	border: 1px solid lightgray;
}

.actionButton,
button.btn.btn-secondary,
button.btn.btn-secondary.actionButton,
button.btn.btn-primary
{
	margin-left: 0.25em;
	margin-right: 0.25em;
	border-radius: 0.25em !important;
}

.titleOrAbbrevBeforeIcon,
.titleOrAbbrevAfterIcon
{
	color: black;
}

.actionButton:hover,
button.btn.btn-secondary:hover
{
	filter: invert(15%);
}

@media screen and (min-width:500px) {
	.actionButton:after
	{
		content: " "attr(data-abbrev);
	}
	.titleOrAbbrevBeforeIcon:before
	{
		content: attr(data-abbrev)" ";
	}
	.titleOrAbbrevAfterIcon:after
	{
		content: " "attr(data-abbrev);
	}
	.searchBox-button:after
	{
		content: " "attr(title);
	}
}

@media screen and (min-width:575px) {
	.actionButton:after
	{
		content: " "attr(title);
	}
	.titleOrAbbrevBeforeIcon:before
	{
		content: attr(title)" ";
	}
	.titleOrAbbrevAfterIcon:after
	{
		content: " "attr(title);
	}
}

@media screen and (max-width:769px) {
	.titleOrAbbrevBeforeIcon:before
	{
		content: attr(data-abbrev)" ";
	}
	.titleOrAbbrevAfterIcon:after
	{
		content: " "attr(data-abbrev);
	}
}


/*************************************************************
	generic utility/helper classes
**************************************************************/

/*** link stuff ***/

.link
{
	cursor: pointer;
	color : $wackadoo-dark-green; 
}

.rollover
{
	cursor: pointer;
}

.linkblue
{
	cursor: pointer;
	color : blue; 
}

.link:hover,
.rollover:hover,
.linkblue:hover
{
	text-decoration: none; // no underline...
	font-weight: 500;
}

.rollover-lightgray-bg
{
	cursor: pointer;
}

.rollover-lightgray-bg:hover
{
	background-color : lightgray;
}

.selected
{
	font-style: italic;
	background-color: beige;
}

/*** font sizes ***/

.extraLarge, .largest
{
	font-size: 2em !important;
}

.larger
{
	font-size: 1.65em !important;
}

.large
{
	font-size: 1.25em !important;
}

.normal,
.regular,
.medium
{
	font-size: 1em !important;
}

.smallish
{
	font-size: 0.85em !important;
}

.small
{
	font-size: 0.70em !important;
}

.tiny
{
	font-size: 0.50em !important;
}

.inherit-font-size
{
	font-size: inherit !important;
}

/*** font decorators ***/

.bold
{
	font-weight: bold;
}

.unbold
{
	font-weight: normal;
}

.italic,
.italics
{
	font-style: italic;
}

.underline
{
	text-decoration: underline;
}

.indent
{
	text-indent: 1em;
}

.hidden
{
	display: none;
}

/*** alignment ***/

.wrap {
	white-space: normal;
}

.nowrap,
.no-wrap
{
	white-space: nowrap;
}

.right
{
	text-align: right !important;
}

.left
{
	text-align: left !important;
}

.center
{
	text-align: center !important;
}

.top
{
	vertical-align: top !important;
}

.bottom
{
	vertical-align: bottom !important;
}

.middle
{
    vertical-align: middle !important;
}

.div-align
{
    display: inline-block;
    float: none;
}

/*** colors ***/

.transparent-bg
{
	background-color : transparent !important;
}

.transparent-bg-important {
    background-color: transparent !important;
}

.aliceblue
{
	color : aliceblue !important;
}

.aliceblue-bg
{
	background-color : aliceblue !important;
}

.antiquewhite
{
	color : antiquewhite !important;
}

.antiquewhite-bg
{
	background-color : antiquewhite !important;
}

.aqua
{
	color : aqua !important;
}

.aqua-bg
{
	background-color : aqua !important;
}

.aquamarine
{
	color : aquamarine !important;
}

.aquamarine-bg
{
	background-color : aquamarine !important;
}

.azure
{
	color : azure !important;
}

.azure-bg
{
	background-color : azure !important;
}

.beige
{
	color : beige !important;
}

.beige-bg
{
	background-color : beige !important;
}

.bisque
{
	color : bisque !important;
}

.bisque-bg
{
	background-color : bisque !important;
}

.black
{
	color : black !important;
}

.black-bg
{
	background-color : black !important;
}

.blanchedalmond
{
	color : blanchedalmond !important;
}

.blanchedalmond-bg
{
	background-color : blanchedalmond !important;
}

.blue
{
	color : blue !important;
}

.blue-bg
{
	background-color : blue !important;
}

.blueviolet
{
	color : blueviolet !important;
}

.blueviolet-bg
{
	background-color : blueviolet !important;
}

.brown
{
	color : brown !important;
}

.brown-bg
{
	background-color : brown !important;
}

.burlywood
{
	color : burlywood !important;
}

.burlywood-bg
{
	background-color : burlywood !important;
}

.cadetblue
{
	color : cadetblue !important;
}

.cadetblue-bg
{
	background-color : cadetblue !important;
}

.chartreuse
{
	color : chartreuse !important;
}

.chartreuse-bg
{
	background-color : chartreuse !important;
}

.chocolate
{
	color : chocolate !important;
}

.chocolate-bg
{
	background-color : chocolate !important;
}

.coral
{
	color : coral !important;
}

.coral-bg
{
	background-color : coral !important;
}

.cornflowerblue
{
	color : cornflowerblue !important;
}

.cornflowerblue-bg
{
	background-color : cornflowerblue !important;
}

.cornsilk
{
	color : cornsilk !important;
}

.cornsilk-bg
{
	background-color : cornsilk !important;
}

.crimson
{
	color : crimson !important;
}

.crimson-bg
{
	background-color : crimson !important;
}

.cyan
{
	color : cyan !important;
}

.cyan-bg
{
	background-color : cyan !important;
}

.darkblue
{
	color : darkblue !important;
}

.darkblue-bg
{
	background-color : darkblue !important;
}

.darkcyan
{
	color : darkcyan !important;
}

.darkcyan-bg
{
	background-color : darkcyan !important;
}

.darkgoldenrod
{
	color : darkgoldenrod !important;
}

.darkgoldenrod-bg
{
	background-color : darkgoldenrod !important;
}

.darkgray
{
	color : darkgray !important;
}

/* this dark to light switch is not a typo */
.darkgray-bg
{
	background-color : lightgray !important;
}

.darkgreen
{
	color : $wackadoo-dark-green !important;
}

.darkgreen-bg
{
	background-color : $wackadoo-dark-green !important;
}

.darkgrey
{
	color : darkgrey !important;
}

/* this dark to light switch is not a typo */
.darkgrey-bg
{
	background-color : lightgray !important;
}

.darkkhaki
{
	color : darkkhaki !important;
}

.darkkhaki-bg
{
	background-color : darkkhaki !important;
}

.darkmagenta
{
	color : darkmagenta !important;
}

.darkmagenta-bg
{
	background-color : darkmagenta !important;
}

.darkolivegreen
{
	color : darkolivegreen !important;
}

.darkolivegreen-bg
{
	background-color : darkolivegreen !important;
}

.darkorange
{
	color : darkorange !important;
}

.darkorange-bg
{
	background-color : darkorange !important;
}

.darkorchid
{
	color : darkorchid !important;
}

.darkorchid-bg
{
	background-color : darkorchid !important;
}

.darkred
{
	color : darkred !important;
}

.darkred-bg
{
	background-color : darkred !important;
}

.darksalmon
{
	color : darksalmon !important;
}

.darksalmon-bg
{
	background-color : darksalmon !important;
}

.darkseagreen
{
	color : darkseagreen !important;
}

.darkseagreen-bg
{
	background-color : darkseagreen !important;
}

.darkslateblue
{
	color : darkslateblue !important;
}

.darkslateblue-bg
{
	background-color : darkslateblue !important;
}

.darkslategray
{
	color : darkslategray !important;
}

.darkslategray-bg
{
	background-color : darkslategray !important;
}

.darkslategrey
{
	color : darkslategrey !important;
}

.darkslategrey-bg
{
	background-color : darkslategrey !important;
}

.darkturquoise
{
	color : darkturquoise !important;
}

.darkturquoise-bg
{
	background-color : darkturquoise !important;
}

.darkviolet
{
	color : darkviolet !important;
}

.darkviolet-bg
{
	background-color : darkviolet !important;
}

.deeppink
{
	color : deeppink !important;
}

.deeppink-bg
{
	background-color : deeppink !important;
}

.deepskyblue
{
	color : deepskyblue !important;
}

.deepskyblue-bg
{
	background-color : deepskyblue !important;
}

.dimgray
{
	color : dimgray !important;
}

.dimgray-bg
{
	background-color : dimgray !important;
}

.dimgrey
{
	color : dimgrey !important;
}

.dimgrey-bg
{
	background-color : dimgrey !important;
}

.dodgerblue
{
	color : dodgerblue !important;
}

.dodgerblue-bg
{
	background-color : dodgerblue !important;
}

.firebrick
{
	color : firebrick !important;
}

.firebrick-bg
{
	background-color : firebrick !important;
}

.floralwhite
{
	color : floralwhite !important;
}

.floralwhite-bg
{
	background-color : floralwhite !important;
}

.forestgreen
{
	color : forestgreen !important;
}

.forestgreen-bg
{
	background-color : forestgreen !important;
}

.fuchsia
{
	color : fuchsia !important;
}

.fuchsia-bg
{
	background-color : fuchsia !important;
}

.gainsboro
{
	color : gainsboro !important;
}

.gainsboro-bg
{
	background-color : gainsboro !important;
}

.ghostwhite
{
	color : ghostwhite !important;
}

.ghostwhite-bg
{
	background-color : ghostwhite !important;
}

.gold
{
	color : gold !important;
}

.gold-bg
{
	background-color : gold !important;
}

.goldenrod
{
	color : goldenrod !important;
}

.goldenrod-bg
{
	background-color : goldenrod !important;
}

.gray
{
	color : gray !important;
}

.gray-bg
{
	background-color : gray !important;
}

.green
{
	color : $wackadoo-other-green !important;
/* 
	color : $wackadoo-green !important;
*/
}

.green-bg
{
	background-color : $wackadoo-other-green !important;
/* 
	background-color : $wackadoo-green !important;
*/
}

.greenyellow
{
	color : greenyellow !important;
}

.greenyellow-bg
{
	background-color : greenyellow !important;
}

.grey
{
	color : grey !important;
}

.grey-bg
{
	background-color : grey !important;
}

.honeydew
{
	color : honeydew !important;
}

.honeydew-bg
{
	background-color : honeydew !important;
}

.hotpink
{
	color : hotpink !important;
}

.hotpink-bg
{
	background-color : hotpink !important;
}

.indianred
{
	color : indianred !important;
}

.indianred-bg
{
	background-color : indianred !important;
}

.indigo
{
	color : indigo !important;
}

.indigo-bg
{
	background-color : indigo !important;
}

.ivory
{
	color : ivory !important;
}

.ivory-bg
{
	background-color : ivory !important;
}

.khaki
{
	color : khaki !important;
}

.khaki-bg
{
	background-color : khaki !important;
}

.lavender
{
	color : lavender !important;
}

.lavender-bg
{
	background-color : lavender !important;
}

.lavenderblush
{
	color : lavenderblush !important;
}

.lavenderblush-bg
{
	background-color : lavenderblush !important;
}

.lawngreen
{
	color : lawngreen !important;
}

.lawngreen-bg
{
	background-color : lawngreen !important;
}

.lemonchiffon
{
	color : lemonchiffon !important;
}

.lemonchiffon-bg
{
	background-color : lemonchiffon !important;
}

.lightblue
{
	color : lightblue !important;
}

.lightblue-bg
{
	background-color : lightblue !important;
}

.lightcoral
{
	color : lightcoral !important;
}

.lightcoral-bg
{
	background-color : lightcoral !important;
}

.lightcyan
{
	color : lightcyan !important;
}

.lightcyan-bg
{
	background-color : lightcyan !important;
}

.lightgoldenrodyellow
{
	color : lightgoldenrodyellow !important;
}

.lightgoldenrodyellow-bg
{
	background-color : lightgoldenrodyellow !important;
}

.lightgray
{
	color : lightgray !important;
}

.lightgray-bg
{
	background-color : lightgray !important;
}

.lightgreen
{
	color : lightgreen !important;
}

.lightgreen-bg
{
	background-color : lightgreen !important;
}

.lightgrey
{
	color : lightgrey !important;
}

.lightgrey-bg
{
	background-color : lightgrey !important;
}

.lightpink
{
	color : lightpink !important;
}

.lightpink-bg
{
	background-color : lightpink !important;
}

.lightsalmon
{
	color : lightsalmon !important;
}

.lightsalmon-bg
{
	background-color : lightsalmon !important;
}

.lightseagreen
{
	color : lightseagreen !important;
}

.lightseagreen-bg
{
	background-color : lightseagreen !important;
}

.lightskyblue
{
	color : lightskyblue !important;
}

.lightskyblue-bg
{
	background-color : lightskyblue !important;
}

.lightslategray
{
	color : lightslategray !important;
}

.lightslategray-bg
{
	background-color : lightslategray !important;
}

.lightslategrey
{
	color : lightslategrey !important;
}

.lightslategrey-bg
{
	background-color : lightslategrey !important;
}

.lightsteelblue
{
	color : lightsteelblue !important;
}

.lightsteelblue-bg
{
	background-color : lightsteelblue !important;
}

.lightyellow
{
	color : lightyellow !important;
}

.lightyellow-bg
{
	background-color : lightyellow !important;
}

.lime
{
	color : lime !important;
}

.lime-bg
{
	background-color : lime !important;
}

.limegreen
{
	color : limegreen !important;
}

.limegreen-bg
{
	background-color : limegreen !important;
}

.linen
{
	color : linen !important;
}

.linen-bg
{
	background-color : linen !important;
}

.magenta
{
	color : magenta !important;
}

.magenta-bg
{
	background-color : magenta !important;
}

.maroon
{
	color : maroon !important;
}

.maroon-bg
{
	background-color : maroon !important;
}

.mediumaquamarine
{
	color : mediumaquamarine !important;
}

.mediumaquamarine-bg
{
	background-color : mediumaquamarine !important;
}

.mediumblue
{
	color : mediumblue !important;
}

.mediumblue-bg
{
	background-color : mediumblue !important;
}

.mediumorchid
{
	color : mediumorchid !important;
}

.mediumorchid-bg
{
	background-color : mediumorchid !important;
}

.mediumpurple
{
	color : mediumpurple !important;
}

.mediumpurple-bg
{
	background-color : mediumpurple !important;
}

.mediumseagreen
{
	color : mediumseagreen !important;
}

.mediumseagreen-bg
{
	background-color : mediumseagreen !important;
}

.mediumslateblue
{
	color : mediumslateblue !important;
}

.mediumslateblue-bg
{
	background-color : mediumslateblue !important;
}

.mediumspringgreen
{
	color : mediumspringgreen !important;
}

.mediumspringgreen-bg
{
	background-color : mediumspringgreen !important;
}

.mediumturquoise
{
	color : mediumturquoise !important;
}

.mediumturquoise-bg
{
	background-color : mediumturquoise !important;
}

.mediumvioletred
{
	color : mediumvioletred !important;
}

.mediumvioletred-bg
{
	background-color : mediumvioletred !important;
}

.midnightblue
{
	color : midnightblue !important;
}

.midnightblue-bg
{
	background-color : midnightblue !important;
}

.mintcream
{
	color : mintcream !important;
}

.mintcream-bg
{
	background-color : mintcream !important;
}

.mistyrose
{
	color : mistyrose !important;
}

.mistyrose-bg
{
	background-color : mistyrose !important;
}

.moccasin
{
	color : moccasin !important;
}

.moccasin-bg
{
	background-color : moccasin !important;
}

.navajowhite
{
	color : navajowhite !important;
}

.navajowhite-bg
{
	background-color : navajowhite !important;
}

.navy
{
	color : navy !important;
}

.navy-bg
{
	background-color : navy !important;
}

.oldlace
{
	color : oldlace !important;
}

.oldlace-bg
{
	background-color : oldlace !important;
}

.olive
{
	color : olive !important;
}

.olive-bg
{
	background-color : olive !important;
}

.olivedrab
{
	color : olivedrab !important;
}

.olivedrab-bg
{
	background-color : olivedrab !important;
}

.orange
{
	color : orange !important;
}

.orange-bg
{
	background-color : orange !important;
}

.orangered
{
	color : orangered !important;
}

.orangered-bg
{
	background-color : orangered !important;
}

.orchid
{
	color : orchid !important;
}

.orchid-bg
{
	background-color : orchid !important;
}

.palegoldenrod
{
	color : palegoldenrod !important;
}

.palegoldenrod-bg
{
	background-color : palegoldenrod !important;
}

.palegreen
{
	color : palegreen !important;
}

.palegreen-bg
{
	background-color : palegreen !important;
}

.paleturquoise
{
	color : paleturquoise !important;
}

.paleturquoise-bg
{
	background-color : paleturquoise !important;
}

.palevioletred
{
	color : palevioletred !important;
}

.palevioletred-bg
{
	background-color : palevioletred !important;
}

.papayawhip
{
	color : papayawhip !important;
}

.papayawhip-bg
{
	background-color : papayawhip !important;
}

.peachpuff
{
	color : peachpuff !important;
}

.peachpuff-bg
{
	background-color : peachpuff !important;
}

.peru
{
	color : peru !important;
}

.peru-bg
{
	background-color : peru !important;
}

.pink
{
	color : pink !important;
}

.pink-bg
{
	background-color : pink !important;
}

.plum
{
	color : plum !important;
}

.plum-bg
{
	background-color : plum !important;
}

.powderblue
{
	color : powderblue !important;
}

.powderblue-bg
{
	background-color : powderblue !important;
}

.purple
{
	color : purple !important;
}

.purple-bg
{
	background-color : purple !important;
}

.rebeccapurple
{
	color : rebeccapurple !important;
}

.rebeccapurple-bg
{
	background-color : rebeccapurple !important;
}

.red
{
	color : red !important;
}

.red-bg
{
	background-color : red !important;
}

.rosybrown
{
	color : rosybrown !important;
}

.rosybrown-bg
{
	background-color : rosybrown !important;
}

.royalblue
{
	color : royalblue !important;
}

.royalblue-bg
{
	background-color : royalblue !important;
}

.saddlebrown
{
	color : saddlebrown !important;
}

.saddlebrown-bg
{
	background-color : saddlebrown !important;
}

.salmon
{
	color : salmon !important;
}

.salmon-bg
{
	background-color : salmon !important;
}

.sandybrown
{
	color : sandybrown !important;
}

.sandybrown-bg
{
	background-color : sandybrown !important;
}

.seagreen
{
	color : seagreen !important;
}

.seagreen-bg
{
	background-color : seagreen !important;
}

.seashell
{
	color : seashell !important;
}

.seashell-bg
{
	background-color : seashell !important;
}

.sienna
{
	color : sienna !important;
}

.sienna-bg
{
	background-color : sienna !important;
}

.silver
{
	color : silver !important;
}

.silver-bg
{
	background-color : silver !important;
}

.skyblue
{
	color : skyblue !important;
}

.skyblue-bg
{
	background-color : skyblue !important;
}

.slateblue
{
	color : slateblue !important;
}

.slateblue-bg
{
	background-color : slateblue !important;
}

.slategray
{
	color : slategray !important;
}

.slategray-bg
{
	background-color : slategray !important;
}

.slategrey
{
	color : slategrey !important;
}

.slategrey-bg
{
	background-color : slategrey !important;
}

.snow
{
	color : snow !important;
}

.snow-bg
{
	background-color : snow !important;
}

.springgreen
{
	color : springgreen !important;
}

.springgreen-bg
{
	background-color : springgreen !important;
}

.steelblue
{
	color : steelblue !important;
}

.steelblue-bg
{
	background-color : steelblue !important;
}

.tan
{
	color : tan !important;
}

.tan-bg
{
	background-color : tan !important;
}

.teal
{
	color : teal !important;
}

.teal-bg
{
	background-color : teal !important;
}

.thistle
{
	color : thistle !important;
}

.thistle-bg
{
	background-color : thistle !important;
}

.tomato
{
	color : tomato !important;
}

.tomato-bg
{
	background-color : tomato !important;
}

.turquoise
{
	color : turquoise !important;
}

.turquoise-bg
{
	background-color : turquoise !important;
}

.ultralightgray,
.ultralightgrey
{
	color: $bg-light !important;
}

.ultralightgray-bg,
.ultralightgrey-bg
{
	background-color: $bg-light !important;
}

.violet
{
	color : violet !important;
}

.violet-bg
{
	background-color : violet !important;
}

.wheat
{
	color : wheat !important;
}

.wheat-bg
{
	background-color : wheat !important;
}

.white
{
	color : white !important;
}

.white-bg
{
	background-color : white !important;
}

.whitesmoke
{
	color : whitesmoke !important;
}

.whitesmoke-bg
{
	background-color : whitesmoke !important;
}

.yellow
{
	color : yellow !important;
}

.yellow-bg
{
	background-color : yellow !important;
}

.yellowgreen
{
	color : yellowgreen !important;
}

.yellowgreen-bg
{
	background-color : yellowgreen !important;
}


/*** borders ***/

.round-corners
{
	border-radius: 0.5em !important;
  overflow: hidden !important;
}

/* same as bootstrap shadow... */
.shadow
{
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}

.noborder
{
	border-left-style: none !important;
	border-left-color: none !important;
	border-right-style: none !important;
	border-right-color: none !important;
	border-top-style: none !important;
	border-top-color: none !important;
	border-bottom-style: none !important;
	border-bottom-color: none !important;
	border-width: 0px !important;
}

.border
{
	border-left-style: solid ;
	border-left-color: #ddd;
	border-right-style: solid ;
	border-right-color: #ddd;
	border-top-style: solid ;
	border-top-color: #ddd;
	border-bottom-style: solid ;
	border-bottom-color: #ddd;
	border-width: 1px ;
}

.border-left
{
	border-left-style: solid ;
	border-left-color: #ddd;
	border-width: 1px ;
}

.border-right
{
	border-right-style: solid ;
	border-right-color: #ddd;
	border-width: 1px ;
}

.border-top
{
	border-top-style: solid ;
	border-top-color: #ddd;
	border-width: 1px ;
}

.border-bottom
{
	border-bottom-style: solid ;
	border-bottom-color: #ddd;
	border-width: 1px ;
}

.no-border
{
	border: none;
}

.no-border-important {
    border: none !important;
}

/*** quote stuff ***/

.quote
{
	font-size: 0.75em ;
	font-style: italic;
}

.quote:before,
.quote:after
{
	content: "\"";
}

.quote-attribution
{
	font-size: 0.75em;
	font-style: italic;
	text-align: right;
}

/*** currency stuff ***/

.currency:before
{
	content: "$";
}

/*** height and width ***/

.fillViewPortWidth {
	width: 100vw ;
}

.fillViewPortHeight {
	height: 100vh ;
}

.fillViewPort {
	width: 100vw ;
	height: 100vh ;
}

.fillAvailableWidth
{
  width: -webkit-fill-available;
/*
  min-width: -webkit-fill-available;
*/
  max-width: -webkit-fill-available;
}

.fillAvailableHeight
{
  height: -webkit-fill-available;
/*
  min-height: -webkit-fill-available;
*/
  max-height: -webkit-fill-available;
}

.fillAvailableSpace
{
  height: -webkit-fill-available;
  min-height: -webkit-fill-available;
  max-height: -webkit-fill-available;
  width: -webkit-fill-available;
  min-width: -webkit-fill-available;
  max-width: -webkit-fill-available;
}

.width-unset
{
	width: unset !important;
}

.width-1-em {
	width: 1em !important;
}

.width-2-em {
	width: 2em !important;
}

.width-3-em {
	width: 3em !important;
}

.width-4-em {
	width: 4em !important;
}

.width-5-em {
	width: 5em !important;
}

.width-6-em {
	width: 6em !important;
}

.width-7-em {
	width: 7em !important;
}

.width-8-em {
	width: 8em !important;
}

.width-9-em {
	width: 9em !important;
}

.width-10-em {
	width: 10em !important;
}

.width-11-em {
	width: 11em !important;
}

.width-12-em {
	width: 12em !important;
}

.width-13-em {
	width: 13em !important;
}

.width-14-em {
	width: 14em !important;
}

.width-15-em {
	width: 15em !important;
}

.width-16-em {
	width: 16em !important;
}

.width-17-em {
	width: 17em !important;
}

.width-18-em {
	width: 18em !important;
}

.width-19-em {
	width: 19em !important;
}

.width-20-em {
	width: 20em !important;
}

.width-25-em {
	width: 25em !important;
}

.width-30-em {
	width: 30em !important;
}

.width-35-em {
	width: 35em !important;
}

.min-width-1-em
{
	min-width: 1em;
}

.min-width-2-em
{
	min-width: 2em;
}

.min-width-3-em
{
	min-width: 3em;
}

.min-width-4-em
{
	min-width: 4em;
}

.min-width-5-em
{
	min-width: 5em;
}

.min-width-6-em
{
	min-width: 6em;
}

.min-width-7-em
{
	min-width: 7em;
}

.min-width-8-em
{
	min-width: 8em;
}

.min-width-9-em
{
	min-width: 9em;
}

.min-width-10-em
{
	min-width: 10em;
}

.min-width-11-em
{
	min-width: 11em;
}

.min-width-12-em
{
	min-width: 12em;
}

.min-width-13-em
{
	min-width: 13em;
}

.min-width-14-em
{
	min-width: 14em;
}

.min-width-15-em
{
	min-width: 15em;
}

.min-width-20-em
{
	min-width: 20em;
}

.min-width-25-em
{
	min-width: 25em;
}

.min-width-30-em
{
	min-width: 30em;
}

.min-width-35-em
{
	min-width: 35em;
}

.height-1-em {
	height: 1em !important;
}

.height-2-em {
	height: 2em !important;
}

.height-3-em {
	height: 3em !important;
}

.height-4-em {
	height: 4em !important;
}

.height-5-em {
	height: 5em !important;
}

.widthViewPort10 {
	width: 10vw;
}

.widthViewPort15 {
	width: 15vw;
}

.widthViewPort20 {
	width: 20vw;
}

.widthViewPort25 {
	width: 25vw;
}

.widthViewPort30 {
	width: 30vw;
}

.widthViewPort35 {
	width: 35vw;
}

.widthViewPort40 {
	width: 40vw;
}

.widthViewPort45 {
	width: 45vw;
}

.widthViewPort50 {
	width: 50vw;
}

.widthViewPort55 {
	width: 55vw;
}

.widthViewPort60 {
	width: 60vw;
}

.widthViewPort65 {
	width: 65vw;
}

.widthViewPort70 {
	width: 70vw;
}

.widthViewPort75 {
	width: 75vw;
}

.widthViewPort80 {
	width: 80vw;
}

.widthViewPort85 {
	width: 85vw;
}

.widthViewPort90 {
	width: 90vw;
}

.widthViewPort95 {
	width: 95vw;
}

.widthViewPort100 {
	width: 100vw;
}

.heightViewPort20 {
	height: 20vh;
}

.heightViewPort25 {
	height: 25vh;
}

.heightViewPort30 {
	height: 30vh;
}

.heightViewPort35 {
	height: 35vh;
}

.heightViewPort40 {
	height: 40vh;
}

.heightViewPort45 {
	height: 45vh;
}

.heightViewPort50 {
	height: 50vh;
}

.heightViewPort55 {
	height: 55vh;
}

.heightViewPort60 {
	height: 60vh;
}

.heightViewPort65 {
	height: 65vh;
}

.heightViewPort70 {
	height: 70vh;
}

.heightViewPort75 {
	height: 75vh;
}

.heightViewPort80 {
	height: 80vh;
}

.heightViewPort85 {
	height: 85vh;
}

.heightViewPort90 {
	height: 90vh;
}

.heightViewPort95 {
	height: 95vh;
}

.heightViewPort100 {
	height: 100vh;
}

.minWidthViewPort20 {
	min-width: 20vw;
}

.minWidthViewPort25 {
	min-width: 25vw;
}

.minWidthViewPort30 {
	min-width: 30vw;
}

.minWidthViewPort35 {
	min-width: 35vw;
}

.minWidthViewPort40 {
	min-width: 40vw;
}

.minWidthViewPort45 {
	min-width: 45vw;
}

.minWidthViewPort50 {
	min-width: 50vw;
}

.minWidthViewPort55 {
	min-width: 55vw;
}

.minWidthViewPort60 {
	min-width: 60vw;
}

.minWidthViewPort65 {
	min-width: 65vw;
}

.minWidthViewPort70 {
	min-width: 70vw;
}

.minWidthViewPort75 {
	min-width: 75vw;
}

.minWidthViewPort80 {
	min-width: 80vw;
}

.minWidthViewPort85 {
	min-width: 85vw;
}

.minWidthViewPort90 {
	min-width: 90vw;
}

.minWidthViewPort95 {
	min-width: 95vw;
}

.minWidthViewPort100 {
	min-width: 100vw;
}

.minHeightViewPort20 {
	min-height: 20vh;
}

.minHeightViewPort25 {
	min-height: 25vh;
}

.minHeightViewPort30 {
	min-height: 30vh;
}

.minHeightViewPort35 {
	min-height: 35vh;
}

.minHeightViewPort40 {
	min-height: 40vh;
}

.minHeightViewPort45 {
	min-height: 45vh;
}

.minHeightViewPort50 {
	min-height: 50vh;
}

.minHeightViewPort55 {
	min-height: 55vh;
}

.minHeightViewPort60 {
	min-height: 60vh;
}

.minHeightViewPort65 {
	min-height: 65vh;
}

.minHeightViewPort70 {
	min-height: 70vh;
}

.minHeightViewPort75 {
	min-height: 75vh;
}

.minHeightViewPort80 {
	min-height: 80vh;
}

.minHeightViewPort85 {
	min-height: 85vh;
}

.minHeightViewPort90 {
	min-height: 90vh;
}

.minHeightViewPort95 {
	min-height: 95vh;
}

.minHeightViewPort100 {
	min-height: 100vh;
}

.maxWidthViewPort20 {
	max-width: 20vw;
}

.maxWidthViewPort25 {
	max-width: 25vw;
}

.maxWidthViewPort30 {
	max-width: 30vw;
}

.maxWidthViewPort35 {
	max-width: 35vw;
}

.maxWidthViewPort40 {
	max-width: 40vw;
}

.maxWidthViewPort45 {
	max-width: 45vw;
}

.maxWidthViewPort50 {
	max-width: 50vw;
}

.maxWidthViewPort55 {
	max-width: 55vw;
}

.maxWidthViewPort60 {
	max-width: 60vw;
}

.maxWidthViewPort65 {
	max-width: 65vw;
}

.maxWidthViewPort70 {
	max-width: 70vw;
}

.maxWidthViewPort75 {
	max-width: 75vw;
}

.maxWidthViewPort80 {
	max-width: 80vw;
}

.maxWidthViewPort85 {
	max-width: 85vw;
}

.maxWidthViewPort90 {
	max-width: 90vw;
}

.maxWidthViewPort95 {
	max-width: 95vw;
}

.maxWidthViewPort100 {
	max-width: 100vw;
}

.maxHeightViewPort20 {
	max-height: 20vh;
}

.maxHeightViewPort25 {
	max-height: 25vh;
}

.maxHeightViewPort30 {
	max-height: 30vh;
}

.maxHeightViewPort35 {
	max-height: 35vh;
}

.maxHeightViewPort40 {
	max-height: 40vh;
}

.maxHeightViewPort45 {
	max-height: 45vh;
}

.maxHeightViewPort50 {
	max-height: 50vh;
}

.maxHeightViewPort55 {
	max-height: 55vh;
}

.maxHeightViewPort60 {
	max-height: 60vh;
}

.maxHeightViewPort65 {
	max-height: 65vh;
}

.maxHeightViewPort70 {
	max-height: 70vh;
}

.maxHeightViewPort75 {
	max-height: 75vh;
}

.maxHeightViewPort80 {
	max-height: 80vh;
}

.maxHeightViewPort85 {
	max-height: 85vh;
}

.maxHeightViewPort90 {
	max-height: 90vh;
}

.maxHeightViewPort95 {
	max-height: 95vh;
}

.maxHeightViewPort100 {
	max-height: 100vh;
}

.halfViewPort {
	width: 50vw ;
	height: 50vh ;
}

.inheritHeightAndWidth
{
	height: inherit ;
	min-height: inherit ;
	max-height: inherit ;
	width: inherit ;
	min-width: inherit ;
	max-width: inherit ;
}

.quarterWidth
{
	width: 25% ;
}

.quarterHeight
{
	height: 25% ;
}

.thirdWidth
{
	width: 33.3% ;
}

.thirdHeight
{
	height: 33.3% ;
}

.halfWidth
{
	width: 50% ;
}

.halfHeight
{
	height: 50% ;
}

.fullWidth
{
	min-width: 100% ;
	max-width: 100% ;
}

.fullHeight
{
	min-height: 100% ;
	max-height: 100% ;
}

.shrinkToFit
{
	max-width: 100% ;
	max-height: 100% ;
}

.shrinkToFitHeight
{
	max-height: 100% ;
}

.shrinkToFitWidth
{
	max-width: 100% ;
}

.fit-content
{
  height: fit-content;
  width: fit-content;
}

.fullHeightAndWidth
{
	min-width: 100% ;
	max-width: 100% ;
	min-height: 100% ;
	max-height: 100% ;
}

.fullWidth99Percent
{
	min-width: 99% ;
	max-width: 99% ;
}

.fullWidthLessTwoEm
{
	min-width: calc(100% - 2em) ;
	max-width: calc(100% - 2em) ;
}

.fullHeightLessTwoEm
{
	min-height: calc(100% - 2em) ;
	max-height: calc(100% - 2em) ;
}

.fullHeightLessOneEm
{
	min-height: calc(100% - 1em) ;
	max-height: calc(100% - 1em) ;
}

.fullHeightLessOneAndAHalfEm
{
	min-height: calc(100% - 1.5em) ;
	max-height: calc(100% - 1.5em) ;
}

.fullHeight99Percent
{
	min-height: 99% ;
	max-height: 99% ;
}

.fullHeightAndWidth99Percent
{
	min-width: 99% ;
	max-width: 99% ;
	min-height: 99% ;
	max-height: 99% ;
}

.shrinkToFit99Percent
{
	max-width: 99% ;
	max-height: 99% ;
}

/*** padding and margins ***/

.marginWholeEm
{
	margin-top: 1em !important;
	margin-bottom: 1em !important;
	margin-left: 1em !important;
	margin-right: 1em !important;
}

.marginHalfEm
{
	margin-top: 0.5em !important;
	margin-bottom: 0.5em !important;
	margin-left: 0.5em !important;
	margin-right: 0.5em !important;
}

.marginQuarterEm
{
	margin-top: 0.25em !important;
	margin-bottom: 0.25em !important;
	margin-left: 0.25em !important;
	margin-right: 0.25em !important;
}

.margin6
{
	margin-top: 6px !important;
	margin-bottom: 6px !important;
	margin-left: 6px !important;
	margin-right: 6px !important;
}

.margin5
{
	margin-top: 5px !important;
	margin-bottom: 5px !important;
	margin-left: 5px !important;
	margin-right: 5px !important;
}

.margin4
{
	margin-top: 4px !important;
	margin-bottom: 4px !important;
	margin-left: 4px !important;
	margin-right: 4px !important;
}

.margin3
{
	margin-top: 3px !important;
	margin-bottom: 3px !important;
	margin-left: 3px !important;
	margin-right: 3px !important;
}

.margin2
{
	margin-top: 2px !important;
	margin-bottom: 2px !important;
	margin-left: 2px !important;
	margin-right: 2px !important;
}

.margin1
{
	margin-top: 1px !important;
	margin-bottom: 1px !important;
	margin-left: 1px !important;
	margin-right: 1px !important;
}

.margin0
{
	margin-top: 0px !important;
	margin-bottom: 0px !important;
	margin-left: 0px !important;
	margin-right: 0px !important;
}

.marginTopQuarterEm
{
	margin-top: 0.25em !important;
}

.marginTopHalfEm
{
	margin-top: 0.5em !important;
}

.marginTopWholeEm,
.marginTop1em
{
	margin-top: 1em !important;
}

.marginTop2em
{
	margin-top: 2em !important;
}

.marginTop3em
{
	margin-top: 3em !important;
}

.marginTop4em
{
	margin-top: 4em !important;
}

.marginTop5em
{
	margin-top: 5em !important;
}

.marginTop6
{
	margin-top: 6px !important; 
}

.marginTop5
{
	margin-top: 5px !important;
}

.marginTop4
{
	margin-top: 4px !important;
}

.marginTop3
{
	margin-top: 3px !important;
}

.marginTop2
{
	margin-top: 2px !important;
}

.marginTop1
{
	margin-top: 1px !important;
}

.marginTop0
{
	margin-top: 0px !important;
}

.marginBottomQuarterEm
{
	margin-bottom: 0.25em !important;
}

.marginBottomHalfEm
{
	margin-bottom: 0.5em !important;
}

.marginBottomWholeEm
{
	margin-bottom: 1em !important;
}

.marginBottomTwoAndAHalfEm
{
	margin-bottom: 2.5em !important;
}

.marginBottomThreeEm
{
	margin-bottom: 3em !important;
}

.marginBottom40
{
	margin-bottom: 40px !important;
}

.marginBottom6
{
	margin-bottom: 6px !important;
}

.marginBottom5
{
	margin-bottom: 5px !important;
}

.marginBottom4
{
	margin-bottom: 4px !important;
}

.marginBottom3
{
	margin-bottom: 3px !important;
}

.marginBottom2
{
	margin-bottom: 2px !important;
}

.marginBottom1
{
	margin-bottom: 1px !important;
}

.marginBottom0
{
	margin-bottom: 0px !important;
}

.marginLeftQuarterEm
{
	margin-left: 0.25em !important;
}

.marginLeftHalfEm
{
	margin-left: 0.5em !important;
}

.marginLeftWholeEm
{
	margin-left: 1em !important;
}

.marginLeft6
{
	margin-left: 6px !important;
}

.marginLeft5
{
	margin-left: 5px !important;
}

.marginLeft4
{
	margin-left: 4px !important;
}

.marginLeft3
{
	margin-left: 3px !important;
}

.marginLeft2
{
	margin-left: 2px !important;
}

.marginLeft1
{
	margin-left: 1px !important;
}

.marginLeft0
{
	margin-left: 0em !important;
}

.marginRightQuarterEm
{
	margin-right: 0.25em !important;
}

.marginRightHalfEm
{
	margin-right: 0.5em !important;
}

.marginRightWholeEm
{
	margin-right: 1em !important;
}

.marginRight6
{
	margin-right: 6px !important;
}

.marginRight5
{
	margin-right: 5px !important;
}

.marginRight4
{
	margin-right: 4px !important;
}

.marginRight3
{
	margin-right: 3px !important;
}

.marginRight2
{
	margin-right: 2px !important;
}

.marginRight1
{
	margin-right: 1px !important;
}

.marginRight0
{
	margin-right: 0px !important;
}

.paddingWholeEm
{
	padding-top: 1em !important; 
	padding-bottom: 1em !important; 
	padding-left: 1em !important;
	padding-right: 1em !important;
}

.paddingHalfEm
{
	padding-top: 0.5em !important;
	padding-bottom: 0.5em !important;
	padding-left: 0.5em !important;
	padding-right: 0.5em !important;
}

.paddingQuarterEm
{
	padding-top: 0.25em !important;
	padding-bottom: 0.25em !important;
	padding-left: 0.25em !important;
	padding-right: 0.25em !important;
}

.padding6
{
	padding-top: 6px !important;
	padding-bottom: 6px !important;
	padding-left: 6px !important;
	padding-right: 6px !important;
}

.padding5
{
	padding-top: 5px !important;
	padding-bottom: 5px !important;
	padding-left: 5px !important;
	padding-right: 5px !important;
}

.padding4
{
	padding-top: 4px !important;
	padding-bottom: 4px !important;
	padding-left: 4px !important;
	padding-right: 4px !important;
}

.padding3
{
	padding-top: 3px !important;
	padding-bottom: 3px !important;
	padding-left: 3px !important;
	padding-right: 3px !important;
}

.padding2
{
	padding-top: 2px !important;
	padding-bottom: 2px !important;
	padding-left: 2px !important;
	padding-right: 2px !important;
}

.padding1
{
	padding-top: 1px !important;
	padding-bottom: 1px !important;
	padding-left: 1px !important;
	padding-right: 1px !important;
}

.padding0
{
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.paddingTopQuarterEm
{
	padding-top: 0.25em !important;
}

.paddingTop3EighthsEm
{
	padding-top: 0.375em !important;
}

.paddingTopHalfEm
{
	padding-top: 0.5em !important;
}

.paddingTopWholeEm
{
	padding-top: 1em !important;
}

.paddingTop6
{
	padding-top: 6px !important; 
}

.paddingTop5
{
	padding-top: 5px !important;
}

.paddingTop4
{
	padding-top: 4px !important;
}

.paddingTop3
{
	padding-top: 3px !important;
}

.paddingTop2
{
	padding-top: 2px !important;
}

.paddingTop1
{
	padding-top: 1px !important;
}

.paddingTop0
{
	padding-top: 0px !important;
}

.paddingBottomQuarterEm
{
	padding-bottom: 0.25em !important;
}

.paddingBottomHalfEm
{
	padding-bottom: 0.5em !important;
}

.paddingBottomWholeEm
{
	padding-bottom: 1em !important;
}

.paddingBottom6
{
	padding-bottom: 6px !important;
}

.paddingBottom5
{
	padding-bottom: 5px !important;
}

.paddingBottom4
{
	padding-bottom: 4px !important;
}

.paddingBottom3
{
	padding-bottom: 3px !important;
}

.paddingBottom2
{
	padding-bottom: 2px !important;
}

.paddingBottom1
{
	padding-bottom: 1px !important;
}

.paddingBottom0
{
	padding-bottom: 0px !important;
}

.paddingLeftQuarterEm
{
	padding-left: 0.25em !important;
}

.paddingLeftHalfEm
{
	padding-left: 0.5em !important;
}

.paddingLeftWholeEm
{
	padding-left: 1em !important;
}

.paddingLeft6
{
	padding-left: 6px !important;
}

.paddingLeft5
{
	padding-left: 5px !important;
}

.paddingLeft4
{
	padding-left: 4px !important;
}

.paddingLeft3
{
	padding-left: 3px !important;
}

.paddingLeft2
{
	padding-left: 2px !important;
}

.paddingLeft1
{
	padding-left: 1px !important;
}

.paddingLeft0
{
	padding-left: 0px !important;
}

.paddingRightQuarterEm
{
	padding-right: 0.25em !important;
}

.paddingRightHalfEm
{
	padding-right: 0.5em !important;
}

.paddingRightWholeEm
{
	padding-right: 1em !important;
}

.paddingRight6
{
	padding-right: 6px !important;
}

.paddingRight5
{
	padding-right: 5px !important;
}

.paddingRight4
{
	padding-right: 4px !important;
}

.paddingRight3
{
	padding-right: 3px !important;
}

.paddingRight2
{
	padding-right: 2px !important;
}

.paddingRight1
{
	padding-right: 1px !important;
}

.paddingRight0
{
	padding-right: 0px !important;
}

/**************************************************************
   Stuff for controlling the height of query result divs,
   like in BulkEMailer...
**************************************************************/

.max-height-1-em
{
	max-height: 1em;
}

.max-height-2-em
{
	max-height: 2em;
}

.max-height-3-em
{
	max-height: 3em;
}

.max-height-4-em
{
	max-height: 4em;
}

.max-height-5-em
{
	max-height: 5em;
}

.max-height-6-em
{
	max-height: 6em;
}

.max-height-7-em
{
	max-height: 7em;
}

.max-height-8-em
{
	max-height: 8em;
}

.max-height-9-em
{
	max-height: 9em;
}

.max-height-10-em
{
	max-height: 10em;
}

.max-height-11-em
{
	max-height: 11em;
}

.max-height-12-em
{
	max-height: 12em;
}

.max-height-13-em
{
	max-height: 13em;
}

.max-height-14-em
{
	max-height: 14em;
}

.max-height-15-em
{
	max-height: 15em;
}

.max-height-16-em
{
	max-height: 16em;
}

.max-height-17-em
{
	max-height: 17em;
}

.max-height-18-em
{
	max-height: 18em;
}

.max-height-19-em
{
	max-height: 19em;
}

.max-height-20-em
{
	max-height: 20em;
}

.max-height-25-em
{
	max-height: 25em;
}

.max-height-30-em
{
	max-height: 30em;
}

.max-height-35-em
{
	max-height: 35em;
}

.min-height-1-em
{
	min-height: 1em;
}

.min-height-2-em
{
	min-height: 2em;
}

.min-height-3-em
{
	min-height: 3em;
}

.min-height-4-em
{
	min-height: 4em;
}

.min-height-5-em
{
	min-height: 5em;
}

.min-height-6-em
{
	min-height: 6em;
}

.min-height-7-em
{
	min-height: 7em;
}

.min-height-8-em
{
	min-height: 8em;
}

.min-height-9-em
{
	min-height: 9em;
}

.min-height-10-em
{
	min-height: 10em;
}

.min-height-11-em
{
	min-height: 11em;
}

.min-height-12-em
{
	min-height: 12em;
}

.min-height-13-em
{
	min-height: 13em;
}

.min-height-14-em
{
	min-height: 14em;
}

.min-height-15-em
{
	min-height: 15em;
}

.min-height-16-em
{
	min-height: 16em;
}

.min-height-17-em
{
	min-height: 17em;
}

.min-height-18-em
{
	min-height: 18em;
}

.min-height-19-em
{
	min-height: 19em;
}

.min-height-20-em
{
	min-height: 20em;
}

.min-height-25-em
{
	min-height: 25em;
}

.min-height-30-em
{
	min-height: 30em;
}

.min-height-35-em
{
	min-height: 35em;
}

.max-width-1-em
{
	max-width: 1em;
}

.max-width-2-em
{
	max-width: 2em;
}

.max-width-3-em
{
	max-width: 3em;
}

.max-width-4-em
{
	max-width: 4em;
}

.max-width-5-em
{
	max-width: 5em;
}

.max-width-6-em
{
	max-width: 6em;
}

.max-width-7-em
{
	max-width: 7em;
}

.max-width-8-em
{
	max-width: 8em;
}

.max-width-9-em
{
	max-width: 9em;
}

.max-width-10-em
{
	max-width: 10em;
}

.max-width-11-em
{
	max-width: 11em;
}

.max-width-12-em
{
	max-width: 12em;
}

.max-width-13-em
{
	max-width: 13em;
}

.max-width-14-em
{
	max-width: 14em;
}

.max-width-15-em
{
	max-width: 15em;
}

.max-width-15-em
{
	max-width: 15em;
}

.max-width-16-em
{
	max-width: 16em;
}

.max-width-17-em
{
	max-width: 17em;
}

.max-width-18-em
{
	max-width: 18em;
}

.max-width-19-em
{
	max-width: 19em;
}

.max-width-20-em
{
	max-width: 20em;
}

.max-width-25-em
{
	max-width: 25em;
}

.max-width-30-em
{
	max-width: 30em;
}

.max-width-35-em
{
	max-width: 35em;
}

.min-width-1-em
{
	min-width: 1em;
}

.min-width-2-em
{
	min-width: 2em;
}

.min-width-3-em
{
	min-width: 3em;
}

.min-width-4-em
{
	min-width: 4em;
}

.min-width-5-em
{
	min-width: 5em;
}

.min-width-6-em
{
	min-width: 6em;
}

.min-width-7-em
{
	min-width: 7em;
}

.min-width-8-em
{
	min-width: 8em;
}

.min-width-9-em
{
	min-width: 9em;
}

.min-width-10-em
{
	min-width: 10em;
}

.min-width-11-em
{
	min-width: 11em;
}

.min-width-12-em
{
	min-width: 12em;
}

.min-width-13-em
{
	min-width: 13em;
}

.min-width-14-em
{
	min-width: 14em;
}

.min-width-15-em
{
	min-width: 15em;
}

.min-width-16-em
{
	min-width: 16em;
}

.min-width-17-em
{
	min-width: 17em;
}

.min-width-18-em
{
	min-width: 18em;
}

.min-width-19-em
{
	min-width: 19em;
}

.min-width-20-em
{
	min-width: 20em;
}

.min-width-25-em
{
	min-width: 25em;
}

.min-width-30-em
{
	min-width: 30em;
}

.min-width-35-em
{
	min-width: 35em;
}

.max-height-100-px
{
	max-height: 100px;
}

.min-height-100-px
{
	min-height: 100px;
}

.max-height-150-px
{
	max-height: 150px;
}

.min-height-150-px
{
	min-height: 150px;
}

.max-height-200-px
{
	max-height: 200px;
}

.min-height-200-px
{
	min-height: 200px;
}

.max-height-250-px
{
	max-height: 250px;
}

.min-height-250-px
{
	min-height: 250px;
}

.max-height-300-px
{
	max-height: 300px;
}

.min-height-300-px
{
	min-height: 300px;
}

.max-height-400-px
{
	max-height: 400px;
}

.min-height-400-px
{
	min-height: 400px;
}

.scrollable
{
	overflow: auto;
}

.scrollable-x
{
	overflow-y: hidden;
	overflow-x: auto;
}

.scrollable-y
{
	overflow-y: auto;
	overflow-x: hidden;
}

.no-resize {
	resize: none;
}

.no-overflow
{
	overflow: hidden;
}

/* trying to make tables scroll in just the tbody section... */

.wrap-scrollable-table
{
	overflow-y: auto;
}

.wrap-scrollable-table th
{
	position: sticky;
	top: 0;
	background: $bg-light;
	border: 0;
}

/* special table formatting for report results */

table.report-results thead tr
{
	text-decoration: underline;
}

table.report-results tr th,
table.report-results tr td
{
	white-space: nowrap;
  overflow-y: auto;
	padding: 0.2em;
	border-width: 1px;
	border-top-style: dotted;
	border-bottom-style: dotted ;
}

tr.numbered-row th:first-child 
{
	background-color: white;
}

tr.numbered-row th:first-child,
tr.numbered-row td:first-child,
div.row.numbered-row div:first-child,
div.d-flex.numbered-row div:first-child
{
	border: none !important;
}

/************************************************************************
	On a fullscreen display, some grid columns might be far too wide for 
	practical use, like long text fields, so we set a max width here.
************************************************************************/

@media screen and (min-width: 768px) {
	div[wackadooGridContentPlaceholder] table thead tr th,
	div[wackadooGridContentPlaceholder] table tbody tr td
	{
		max-width: 25vw;
	}
}

/*******************************************************************
  Stuff we use on the grid page controls...
*******************************************************************/

button.btn.btn-outline-secondary.wackadoo-grid-control,
button.btn.btn-outline-secondary.wackadoo-grid-search-control
{
	color: black;

	background-color: whitesmoke;
	border: 1px solid lightgray;

	font-size: 0.875rem;
	line-height: 1.25;
	text-align: center;
	vertical-align: middle;
}

input.wackadoo-grid-search-control
{
	color: black;

	border: 1px solid lightgray;

	font-size: 0.875rem;
	text-align: left;
    vertical-align: middle;


	padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0.25em;
    padding-right: 0.25em;
}

.wackadoo-grid-control:hover,
.wackadoo-grid-search-control:hover
{
	filter: invert(15%);
}

.wackadoo-grid-control:focus
.wackadoo-grid-search-control:focus
{
	outline: none;
}

.round-right-corners
{
    border-top-left-radius:     0       !important;
    border-bottom-left-radius:  0       !important;
    border-top-right-radius:    0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.round-left-corners
{
    border-top-left-radius:     0.25rem !important;
    border-bottom-left-radius:  0.25rem !important;
    border-top-right-radius:    0       !important;
    border-bottom-right-radius: 0       !important;
}

.round-all-corners
{
    border-top-right-radius:    0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    border-top-left-radius:     0.25rem !important;
    border-bottom-left-radius:  0.25rem !important;
}


/*************************************************************
  customize the sign-up button
**************************************************************/

button.btn.btn-primary.signUpButton
{
	border-top-left-radius: 50% !important;
	border-top-right-radius: 50% !important;
	border-bottom-left-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
	outline: none !important;
}

/*************************************************************
  CUSTOMIZE THE CAROUSEL
**************************************************************/

.staticCarouselText
{
	margin-right: 0em;
	
	background: url() no-repeat center center fixed;

	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

@media screen and (max-width: 1270px) {
	.staticCarouselText{
		font-size: 1em;
	}
}

@media screen and (max-width: 1150px) {
	.staticCarouselText{
		font-size: 0.85em;
	}
}

@media screen and (max-width: 768px) {
	.staticCarouselText{
		font-size: 0.8em;
	}
}

@media screen and (min-width: 768px) {
	.carousel-caption {
		right: 1em;
	}
}

@media screen and (max-width: 767px) {
	.carousel-caption {
    padding-bottom: 0px !important;
    bottom: 0px !important;
		right: 5% !important;
	}
}

@media screen and (max-width: 400px) {
	.staticCarouselText{
		font-size: 0.8em;
	}
}

/******************************************************
 ******************************************************
  field styles
 ******************************************************
  ****************************************************/
.fieldSelected,
.fieldSelected .ngb-tp-input.form-control
{
	color : green ;
/*
	font-weight: bold;
*/
}

.fieldChanged,
.fieldChanged:focus,
.fieldChanged .ngb-tp-input.form-control
{
	border-color : blue !important;
}

wackadoo-field .form-check-input.fieldChanged 
{
	outline: blue solid thin;
}

/* NOTE: This placeholder class does NOT appear to work... */

.fieldChanged::placeholder,
.fieldChanged::-webkit-placeholder,       /* Chrome/Opera/Safari ?? */
.fieldChanged::-webkit-input-placeholder, /* Chrome/Opera/Safari ?? */
.fieldChanged::-moz-placeholder,          /* Firefox 19+ */
.fieldChanged:-ms-input-placeholder,      /* IE 10+ */
.fieldChanged:-moz-placeholder,           /* Firefox 18- */
.fieldChanged .ngb-tp-input.form-control::placeholder,
.fieldChanged .ngb-tp-input.form-control::-webkit-placeholder,       /* Chrome/Opera/Safari ?? */
.fieldChanged .ngb-tp-input.form-control::-webkit-input-placeholder, /* Chrome/Opera/Safari ?? */
.fieldChanged .ngb-tp-input.form-control::-moz-placeholder,          /* Firefox 19+ */
.fieldChanged .ngb-tp-input.form-control:-ms-input-placeholder,      /* IE 10+ */
.fieldChanged .ngb-tp-input.form-control:-moz-placeholder            /* Firefox 18- */
{
  border-color: pink !important;
}

.fieldError,
.fieldError .ngb-tp-input.form-control
{
	border-color : red !important;
	background-color: yellow !important;
}

wackadoo-field .form-check-input 
{
	margin-left: 0.25em !important;
	margin-right: 0.25em !important;
  margin-top: 0.25em !important;
	position: relative;
}

/******************************************************
 ******************************************************
   misc page or app specific styles 
 ******************************************************
******************************************************/

.login
{
	min-width: 200px ;
	max-width: 400px ;
	padding-bottom: 2em;

	margin: auto;
	margin-top: 15vh;
}

.login input.form-control
{
	font-size: 1.1em !important;
	height: calc(1.5em + 0.75em + 2px) !important;
	padding: 0.375rem 0.75rem !important;
}

/******************************************************
 ******************************************************
	main, menu, banner and footer stuff 
 ******************************************************
******************************************************/

/****************************************************************
  Make sure that the header and footer are provided for in 
  the margins of the wackadoo-main div...
****************************************************************/

.wackadoo-main
{
    margin-top: 48px; // same as banner height...
    margin-bottom: 34px; // same as footer height
    padding: 0.5em;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}


/****************************************************************
  Side menu navigation style...
  (Code toggles width between auto and 0 to control visibility...)
****************************************************************/

.sideNavMenu {
  /* height: 100%; 100% Full-height */
  width: auto; /* auto or 0 width - toggle this as needed in code */
  position: fixed; /* Stay in place */
  z-index: 50; /* Stay on top */
  top: 55px; /* Place content 55px from the top since main margin is 54px */
  left: 0;
  background-color: transparent;
  opacity: 1;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 1s; /* transition effect to slide in the sidenav */
}

.sideNavMenu nav-link
{
    width: auto;
    background-color: $bg-light;
    opacity: 1;
}

.wackadoo-footer
{
  font-size: 0.85em;
	padding-bottom: 0.25em ;
}

wackadoo-banner
{
    font-size: 0.85em;
    // font-size: 1em;
}

wackadoo-banner nav 
{
    height: 48px;  // same as main margin-top...
}

.navbar
{
    padding-top: 4px;
}

.navbar-toggler
{
    height: 1.75em;
    width: 1.75em;
    padding-left: 2px;
    padding-right: 2px;
}

.navbar-toggler-icon
{
    height: 1.25em;
}

.userLogo 
{
	height: 1.25em ;
}

.navBarLogo
{
  margin-top: 0.5em;
	height: 2em;
}

.navbar-brand
{
    margin-top: 0;
    margin-bottom: 0;
}

.navbar .navbar-brand
{
		padding-top: 0px;
}

.navBarAppName
{
    color: black;
    text-decoration: bold;
    cursor: pointer;
    // font-size: 1em ;
    // padding-top: 7px;
}

.nav-item .dropdown-menu
{
    padding-top: 0;
    padding-bottom: 0;
    border: 0;
    background-color: transparent;
}

.dropdown-toggle::after
{
	margin-left: 0 !important;
	vertical-align: 0.15em !important;
}

.dropdown-divider {
    display: none;
}

.dropdown-header
{
    margin-top:0;
    margin-bottom: 0;
    font-weight:  200;
    font-style: bold;
    background-color: white;

    border: 1px solid lightgray;
    border-radius: 0.25em;
}

a.dropdown-item 
{
    font-size: 1em;
    text-align: right;
    background-color: $bg-light;
    width: auto;
    border: 1px solid lightgray;
    padding-left: 0.5em;
    padding-right: 0.5em;
    opacity: 1;
    border-radius: 0.25em;

    cursor: pointer;
}

// we call it a tablet instead of a full screen when width is <= 1024 
// (note: we over-rode Bootstrap 4's "lg" default of 992 in variables.scss)
@media screen and (max-width:1180.1px) {

    .navbar
    {
        padding-top: 0px !important;
		}
		
		// .navbar-toggler
		// {
		// 	margin-top: -12px;
		// }
    
		.navBarLogo
		{
			margin-top: 0.375em;
		}

		.navbar .navbar-brand
    {
        padding-top: 0px;
    }

    .nav-link
    {
        font-size: 1em;
        text-align: right;
        background-color: $bg-light; // same as bg-light
        width: auto;
        border: 1px solid lightgray;
        padding-left: 1em;
        padding-right: 1em;
        opacity: 1;

        border-radius: 0.25em;
    }

}

.doubleBannerLine 
{
	font-size: 0.70em;
	vertical-align: middle;
	padding-left: 0.67em;
	padding-right : 0.67em;
}

/******************************************************
 ******************************************************
	modal dialog stuff used by alert, confirm, prompt 
 ******************************************************
*****************************************************/

.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: lightgray;
	opacity: 0.5;
	z-index: 2100 !important;
}

.modal-box {
	position: fixed;

	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	padding: 16px;
	z-index: 2101 !important;
	background-color: white;
	box-shadow: 0 2px 8px  rgba(0,0,0,0.26);

	border-radius: 1em;

	max-height: 80vh;
	max-width: 80vw;
	
}

@media screen and (max-width:768px) {
	.modal-box
	{
		width: 80vw;
	}
}

.modal-box.transparent-bg {
  box-shadow: none !important;
}

wackadoo-e-mailer .modal-backdrop {
  z-index: 90 !important;
}

wackadoo-e-mailer .modal-box {
	width: 80vw;
	height: 80vh;

	z-index: 2091 !important;
}

wackadoo-resource-modal .modal-backdrop {
	z-index: 2090 !important;
}

wackadoo-resource-modal .modal-box {
	z-index: 2091 !important;
}

.modal-box > wackadoo-chart-block > div.card {
	width: 67vw ;
	height: auto ;
}

/******************************************************
 ******************************************************
	please-wait / lds-roller  
 ******************************************************
******************************************************/

.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
	height: 64px;
	background-color : transparent !important;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
/*
  background: #fff;
*/
  background: $wackadoo-green;
  margin: -3px 0 0 -3px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/*******************************************************************
  bootstrap over-rides...
*******************************************************************/

.table th,
.table td
{
  padding: 0.25em !important;
}

.table tr:last-child td
{
	border-bottom-style: solid ;
	border-bottom-color: #ddd;
	border-width: 1px ;
}

.table th
{
	vertical-align: middle !important;
}

wackadoo-e-mailer input.form-control
{
	width: 100%;
}

select.form-control
{
	width: auto;
}

select.form-control option,
.type-ahead-option
{
	font-size: small;
}

ngb-typeahead-window[role="listbox"]
{
	padding-top: 0em !important;
	margin-top: 0em !important;
}

ngb-typeahead-window {
  max-height: 7em;
  overflow-y: auto;
  overflow-x: hidden;
}

.form-control,
input.form-control,
select.form-control,
input[ngbTypeahead]
{
	display: inline-block;
	
	font-size: 0.9em !important;
	line-height: unset !important;
	height: calc(1.33em + 2px) !important;

	padding-right: 0.5em !important;
	padding-bottom: 0em !important;
	padding-top: 0em !important;
	padding-left: 0.5em !important;
}

wackadoo-field-password-show-me .input-group-append 
{
	height: calc(1.33em) !important;
}

.input-group-text {
	font-size: 0.9em      !important;
  line-height: 0        !important;
  padding-left: 0.25em  !important;
  padding-right: 0.25em !important;
}

.nav-link 
{
	cursor: pointer;
}

.navbar .nav-link 
{
	padding-left: 0.5em !important;
	padding-right: 0.5em !important;
	border: none;
}

.nav-link:hover 
{
	background-color: $bg-light;
}

.default-cursor-important {
    cursor: default !important;
}

.jumbotron
{
	border-radius: 1em !important;
}

.card {
	margin: 0.5em;
	border-radius: 8px;
}

.card-header:first-child {
	border-radius: 8px 8px 0em 0em;
}

.card-header {
	padding: 0.25em 0.67em !important;
	border-radius: 8px 8px 0em 0em;
	background-color: lightgray;
	font-weight: bold;
	font-style: italic;
}

.card-body {
	padding: 0.25em 0.67em !important;
	border-radius: 0em 0em 8px 8px;
}

/* Now we compensate for the weird padding we just put in */ 

.card-body .row {
	margin-right: -0.67em !important;
	margin-left: -0.67em !important;
}

.well
{
	margin: 2em;
	padding: 1em;
	border: 1px;
	border-radius: 1em;
	background-color: lightgray;
}


/************************************************
  Carousel for home and public pages...
************************************************/

ngb-carousel {
	height: 30%;
	padding-left: 0px;
	padding-right: 0px;
}

.faded-image {
	object-fit: cover;
	height: 250px;
	width: 100%;
	border: none;
}

/****************************************************
	Got carousel fade logic from a couple places out on 
	the web, and then adapted it to work w/ng-bootstrap
*****************************************************/

.carousel-item {
	display: block;
  opacity: 0;
  -moz-transition: opacity ease-in-out .7s;
  -o-transition: opacity ease-in-out .7s;
  -webkit-transition: opacity ease-in-out .7s;
  transition: opacity ease-in-out .7s;
  z-index:1;
}

.carousel-item.active {
	display: block;
  opacity: 1;
  -moz-transition: opacity ease-in-out .7s;
  -o-transition: opacity ease-in-out .7s;
  -webkit-transition: opacity ease-in-out .7s;
  transition: opacity ease-in-out .7s;
  z-index:2;
}


/*************************************************************
 field styles
*************************************************************/

/* string field / text area */

textarea.form-control
{
	display: inline-block;

	min-width: 100% ;
	max-width: 100% ;

	height: unset !important;
	min-height: 100% ;
	max-height: 100% ;

	overflow: auto;
	resize: none;
}

/* numeric field stuff */

/* remove up/down spinner arrows on numeric fields */

/* Chrome, Safari, Edge, Opera */
wackadoo-field-real-number input[type=number]::-webkit-outer-spin-button,
wackadoo-field-whole-number input[type=number]::-webkit-outer-spin-button,
wackadoo-field-real-number input[type=number]::-webkit-inner-spin-button,
wackadoo-field-whole-number input[type=number]::-webkit-inner-spin-button
{
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
wackadoo-field-real-number input[type=number],
wackadoo-field-whole-number input[type=number]
{
  -moz-appearance: textfield;
}

/* tighten up white space on currency formatting */

wackadoo-field-real-number .input-group .input-group-prepend > .input-group-text,
wackadoo-field-whole-number .input-group .input-group-prepend > .input-group-text,
wackadoo-field-real-number .input-group input[type=number].form-control,
wackadoo-field-whole-number .input-group input[type=number].form-control
{
    padding-right: 2px !important;
		padding-left: 2px !important;
}

/* date field - input */

wackadoo-field-date div.input-group-append > button
{
	padding-right: 0.25em !important;
	padding-bottom: 0em !important;
	padding-top: 0em !important;
	padding-left: 0.25em !important;
	font-size: 0.81em;
	height: 1.63em;
}

wackadoo-field-date input.form-control.dateField,
wackadoo-field-date input.form-control.dateField::placeholder
{
	width: 6.5em !important;
	padding-right: 0.15em !important;
	padding-left: 0.15em !important;
}

ngb-timepicker 
{
	font-size: 1em !important;
}
.ngb-tp-input-container
{
	width: unset !important;
}

.ngb-tp-input.form-control,
.ngb-tp-meridian > button
{
	font-size: 0.9em !important;
	line-height: unset !important;
	text-align: center !important;

	padding-right: 0em !important;
	padding-bottom: 0em !important;
	padding-top: 0em !important;
	padding-left: 0em !important;

	border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;	

}

.ngb-tp-input.form-control
{
	height: calc(1.33em + 2px) !important;
	width: 1.67em !important;
}

.ngb-tp-meridian > button
{
	width: 2em !important;
	height: 1.44em !important;
}

ngb-timepicker .ngb-tp-meridian
{
	font-size: 1em !important;
}

.ngb-tp:before,
.ngb-tp:after {
   content:"";
   display:table;
}
.ngb-tp:after {
   clear:both;
}
.ngb-tp {
   zoom:1; /* For IE 6/7 (trigger hasLayout) */
}

.ngb-tp > .ngb-tp-spacer
{
	width: 0.5em;
  height: 1em;
	font-size: 0.9em;
	margin-top: -8px;
}

.ngb-tp > .ngb-tp-spacer.ngb-tp-spacer::lastChild{
	width: 0;
}

/* binary text file textarea that should max out their height... */

textarea.form-control[name="templateFileContent"]
{
	font-family: monospace;
}

/* binary file stuff... */

img {
	max-width: 100% ;
	max-height: 100% ;
}

/* eliminate the superfluous whitespace above the braintree drop-in ... */

.braintree-placeholder {
	margin-bottom: 0;
  height: 0;
}

/* the next field, if any... */

