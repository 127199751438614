// Bootstrap 4 original 
// $blue:    	#007bff !default;
// $green:   	#28a745 !default;

$wackadoo-green:       #1BB946;
$wackadoo-dark-green:  #085916;
$wackadoo-other-green: #0D9625;

// making "Bootstrap v4 blue" into "wackadoo dark green"
$blue:                 $wackadoo-dark-green    !default;

// same as Bootstrap 4's bg-light
$bg-light:             #f8f9fa;


// Over-riding Boostrap 4's Grid breakpoints
// (lg WAS 992... then 1024.1... now it's 1180.1 to cover the iPad Air)

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1180.1px,
  xl: 1200px
) !default;

// Stripe's branding color
$stripe-blue:       #635BFF;
